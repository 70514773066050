import React from "react";

const featureContent = [
  {
    delayAnimation: "0",
    colorOfBorder: "#274be2",
    icon: "38",
    title: "Let unverified people in",
    description: `Every single profile you see on our app has been verified by a real human. We are very serious about preventing catfishing.`,
    extraStyleTitle:{marginLeft:'-5px'},
    extraStyleImage:{marginLeft:'-3px'}
  },
  {
    delayAnimation: "0",
    colorOfBorder: "#FF8C00",
    icon: "114",
    title: "Sort, Categorize, Rank, etc.",
    description: `Messing with our users is not how we make money. Our business model is PPM (Pay Per Match). We only make money when you are matched.`,
    extraStyleTitle:{marginLeft:'-3px'},
    extraStyleImage:{marginLeft:'-3px'}
  },
  {
    delayAnimation: "0",
    colorOfBorder: "#cf4646",
    icon: "113",
    title: "Shadow Ban",
    description: `We don't have a shadow ban policy because we don't need one.
    If you are on our app, then you are on our app 100%.`,
  },
  {
    delayAnimation: "0",
    colorOfBorder: "#a8324e",
    icon: "112",
    title: "Permanent Ban",
    description: `Unlike most other apps, we don't do that here. 
    Our biggest punishment is keeping you out of our app for 7 days.`,
  },
];

const FancyFeatureNinteen = () => {
  return (
    <div className="row" style={{ marginTop: '-20px' }}>
      {featureContent.map((val, i) => (
        <div
          className="col-lg-6"
          key={i}
        >
          <div
            className="block-style-nineteen"
            style={{ borderLeftColor: val.colorOfBorder }}
          >
            <div className="d-flex align-items-start" style={{...val.extraStyleImage}}>
              <img
                src={`images/icon/${val.icon}.svg`}
                alt="icon"
                className="icon"
              />
              <div className="text" style={{...val.extraStyleTitle}}>
                <h4 style={{paddingTop:'10px'}}>{val.title}</h4>
              </div>
            </div>
            <p style={{marginTop:'20px'}}>{val.description}</p>

          </div>
          {/* /.block-style-nineteen */}
        </div>
      ))}

      {/* End .col */}
    </div>
  );
};

export default FancyFeatureNinteen;
