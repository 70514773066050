import React from "react";

import facebook from "../../../../src/assets/images/social_icons/facebook.png";
import instagram from "../../../../src/assets/images/social_icons/instagram.png";
import twitter from "../../../../src/assets/images/social_icons/twitter.png";
import tiktok from "../../../../src/assets/images/social_icons/tiktok.png";
import youtube from "../../../../src/assets/images/social_icons/youtube.png";
import linkedin from "../../../../src/assets/images/social_icons/linkedin.png";
import discord from "../../../../src/assets/images/social_icons/discord.png";
import minglesea from "../../../../src/assets/images/social_icons/minglesea.png";

const ContactAddressTwo = () => {
  return (
    <div className="row justify-content-center">

      {/* <div className="col-md-4 col-sm-6">
        <div className="address-info">
          <div className="icon">
            <img src="images/icon/44.svg" alt="icon" />
          </div>
          <div className="title">Location</div>
          <p className="font-rubik">
            12 Marina Boulevard, Tower 3, <br />#30 - 03
            Marina Bay Financial Centre, Singapore 018982
          </p>
        </div>
      </div> */}

      <div className="col-md-4 col-sm-6">
        <div className="address-info">
          <div className="icon">
            <img src="images/icon/45.svg" alt="icon" />
          </div>
          <div className="title">Contact</div>
          <p className="font-rubik">
            support@minglesea.com <br />
          </p>
          {/* <p className="font-rubik">
            {'(+65) 999-999-999'}
          </p> */}
        </div>{" "}
        {/* /.address-info */}
      </div>
      {/* End .col */}

      <div className="col-md-4 col-sm-6">
        <div className="address-info">
          <div className="icon">
            <img src="images/icon/46.svg" alt="icon" />
          </div>
          <div className="title">Social</div>
          <p className="font-rubik" style={{ marginBottom: '40px' }}>
            We are not on any social media platform
          </p>
          <div style={{ position: 'relative' }}>
            <div style={{
              position: 'absolute', top: '21px', left: 0, width: '100%', height: '2px', backgroundColor: 'black',
              zIndex: 10, marginLeft: '0%'
            }}></div>
            <ul className="d-flex justify-content-center">
              <li style={{ marginRight: '10px', marginLeft: '10px' }}>
                <img src={facebook} alt="MingleSea" style={{ width: '100px', height: 'auto' }} />
              </li>
              <li style={{ marginRight: '10px' }}>
                <img src={instagram} alt="MingleSea" style={{ width: '100px', height: 'auto' }} />
              </li>
              <li style={{ marginRight: '10px' }}>
                <img src={twitter} alt="MingleSea" style={{ width: '100px', height: 'auto' }} />
              </li>
              <li style={{ marginRight: '10px' }}>
                <img src={tiktok} alt="MingleSea" style={{ width: '100px', height: 'auto' }} />
              </li>
              <li style={{ marginRight: '10px' }}>
                <img src={linkedin} alt="MingleSea" style={{ width: '100px', height: 'auto' }} />
              </li>
              <li style={{ marginRight: '10px', marginTop: '6px' }}>
                <img src={youtube} alt="MingleSea" style={{ width: '100px', height: 'auto' }} />
              </li>
              <li style={{ marginTop: '4px', marginRight: '10px' }}>
                <img src={discord} alt="MingleSea" style={{ width: '100px', height: 'auto' }} />
              </li>
            </ul>
          </div>
        </div>
      </div>


      <hr />

      <div className="row justify-content-center">

        <div className="col-md-6 col-sm-6">
          <div className="address-info">
            <div className="icon" style={{ marginTop: '50px' }}>
              <img src={minglesea} alt="MingleSea" />
            </div>
            {/* <div className="title">Social Media</div> */}
            <p className="font-rubik" style={{ marginBottom: '20px', marginTop: '20px' }}>minglesea.com is our only website</p>
          </div>{" "}
          {/* /.address-info */}
        </div>
        {/* End .col */}

      </div>


    </div>

  );
};

export default ContactAddressTwo;
